
/*  Can be removed once utility less file has moved outside of .wink */

/*  To replace with wink-visually-hidden utility class */
.root {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    white-space: nowrap !important;
}
