/* File allow-listed for globally scoped CSS. Reach out to #fep-team with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

:root {
    /*  Variants */
    --hero-bg--default: var(--bg-lvl-1-color);
    --hero-bg--info: rgba(197, 219, 242, 0.2); /*  cornflower 20% */
    --hero-bg--new: rgba(249, 224, 250, 0.2); /*  radish 20% */
    --hero-bg--success: rgba(216, 234, 204, 0.2); /*  cucumber 20% */
    --hero-bg--warning: rgba(251, 238, 202, 0.2); /*  squash 20% */
    --hero-bg--error: rgba(251, 207, 189, 0.2); /*  yam 20% */
}

.root {
    padding: var(--spacing-10) 0;
}

.withSecondary {
    padding: var(--spacing-8) 0;
}

.secondaryContent {
    order: 1;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.secondaryContent > img {
    max-width: 100%;
    max-height: 100%;
}


/*  TO-DO For Color 2.0 update: */

/*  Variant names should be decoupled from the value in order to scale. */
.coconut {
    background-color: var(--hero-bg--default);
}

.cornflower {
    background-color: var(--hero-bg--info);
}

.cucumber {
    background-color: var(--hero-bg--success);
}

.radish {
    background-color: var(--hero-bg--new);
}

.squash {
    background-color: var(--hero-bg--warning);
}

.yam {
    background-color: var(--hero-bg--error);
}

.jasmine {
    background-color: var(--bg-lvl-2-color);
}
