/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import (reference) '@mc/wink/styles/tokens.less';

:root {
    --checkmark-size: calc(var(--base-unit) * 6);
}

.wrapper {
    background: var(--bg-lvl-1-color);
    border: 1px solid var(--border-light-color);
    border-radius: var(--border-card-radius);
    box-sizing: border-box;

    transition: var(--box-shadow-transition);

    &:hover {
        border-color: var(--border-dark-color);
        background: var(--bg-lvl-2-color);
        cursor: pointer;
        box-shadow: var(--elevation-lvl-1);
    }

    *:hover {
        cursor: pointer;
    }

    &:focus-within {
        background: var(--bg-lvl-2-color);
        border-color: var(--border-interactive-color);
        outline: 1px solid var(--border-interactive-color);
        box-shadow: var(--elevation-lvl-1);
    }

    &.checked {
        border-color: var(--border-interactive-color);
        background: var(--bg-interactive-color);

        &:focus-within {
            background: var(--bg-interactive-color);
            outline: 1px solid var(--border-interactive-color);
        }
    }

    &.disabled {
        border-color: var(--disabled-color);
        background: var(--bg-lvl-1-color);
        opacity: 0.3;
        cursor: not-allowed;
        * { cursor: not-allowed; }
    }
}

.selection {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    height: 100%;

    input {
        vertical-align: middle;
    }

    .unselectedCircle,
    .checkIcon {
        vertical-align: middle;
    }

    .unselectedCircle {
        border-radius: 50%;
        border: 1px solid var(--border-input-color);

        // Set to prevent stretching in layout primitives
        max-height: var(--checkmark-size);
        max-width: var(--checkmark-size);
        height: 100%;
        width: 100%;
    }

    // Small Icon
    .checkIcon {
        fill: var(--interactive-color);
        height: var(--checkmark-size);
        width: var(--checkmark-size);
    }

    .imageContainer {
        max-height: calc(var(--base-unit) * 16);
    }

    // Large Icon
    .imageContainer .checkIcon {
        height: calc(var(--base-unit) * 16);
        width: calc(var(--base-unit) * 16);
    }
}

.error .wrapper {
    border-color: var(--border-error-color);

    &:focus {
        outline-color: var(--border-error-color);
    }
}

.card {
    width: 100%;
    padding: var(--spacing-6);
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3);
    justify-content: center;
}

.icon {
    width: calc(var(--checkmark-size) + 1px);
    height: calc(var(--checkmark-size) + 1px);
}

.image {
    height: calc(var(--base-unit) * 16);
}

.badge {
    margin-bottom: var(--spacing-2);
}

// Did not use Text component for styling
// these elements because the proptype allows nodes.
// Manually styling secondary text.
.label {
    font-weight: var(--font-weight-bold);
    max-width: var(--readable-max-width);
}
.description {
    font: var(--font-text-small);
    color: var(--text-secondary-color);
    max-width: var(--readable-max-width);
}

.additional {
    background-color: var(--bg-interactive-color);

    width: 100%;
    padding: var(--spacing-3);

    text-align: center;
}

.simple {
    .card {
        padding: var(--spacing-3);
        gap: 0;
    }
}

.horizontal {
    .card {
        display: flex;
        align-items: center;
        gap: var(--spacing-6);
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
    }

    .copy {
        /** For i18n purposes - This is the same as left if direction is left-to-right and right if direction is right-to-left. */
        text-align: start;
    }

    .imageContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        .image {
            max-width: calc(var(--base-unit) * 12);
            height: 100%;
        }

        .checkIcon {
            height: calc(var(--base-unit) * 12);
            width: calc(var(--base-unit) * 12);
        }
    }
}
