/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import (reference) '@mc/wink/styles/tokens.less';

/*------------------------------------*\
  #DataWell
\*------------------------------------*/

:root {
    --data-well-font-size-xs: var(--font-size-medium);
    --data-well-font-size-sm: var(--font-size-h3);
    --data-well-font-size-md: 4rem;
    --data-well-font-size-lg: 5.6rem;
}

:global(.project-runway) {
    --data-well-font-size-sm: var(--font-size-h5);
}

.root {
    background: var(--bg-lvl-1-color);
    border-radius: var(--border-data-radius);

    // Appearance variants
    &.default {
        border: 1px solid var(--border-light-color);
    }

    &.none {
        border: 0;

        .rootItem {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // Size variants
    &.xs {
        --data-well-size: 16rem;
    }
    &.sm {
        --data-well-size: 16rem;
    }
    &.md {
        --data-well-size: 20rem;
    }
    &.lg {
        --data-well-size: 32rem;
    }

    // Alignment variants
    &.start,
    &.center {
        display: flex;
        flex-wrap: wrap;
    }

    &.start {
        justify-content: left;
        text-align: start;
    }
    &.center {
        justify-content: space-around;
        text-align: center;
    }

    .rootItem {
        flex-basis: var(--data-well-size);
    }
}

/*------------------------------------*\
    #DataWell Item
\*------------------------------------*/

.rootItem {
    width: var(--data-well-size);
    padding: var(--spacing-7) var(--spacing-6);
}

// Value
.valueContainer {
    .hasMiscText & {
        display: inline-flex;
        gap: var(--spacing-2);
    }
}

.wrapText .valueContainer {
    flex-direction: column;
}

.value {
    font-family: var(--typeface-brand);
    font-weight: var(--font-weight-bold);
    line-break: anywhere;
    line-height: 1;

    .xs & {
        font-size: var(--data-well-font-size-xs);
    }
    .sm & {
        font-size: var(--data-well-font-size-sm);
    }
    .md & {
        font-size: var(--data-well-font-size-md);
    }
    .lg & {
        font-size: var(--data-well-font-size-lg);
    }
}

:global(.project-runway) {
    .value {
        font-family: var(--typeface-base);
    }
}

// MiscText
.miscTextContainer {
    place-self: center start;
    .wrapText.start & {
        place-self: start;
    }
}

.miscText {
    font-weight: var(--font-weight-bold);

    // Conditional styles when value is either trending positive or negative
    &.positive {
        color: var(--text-success-color);
    }
    &.negative {
        color: var(--text-error-color);
    }
    &.neutral {
        color: var(--text-primary-color);
    }
}

// Label
.label {
    padding-top: var(--spacing-2);

    .xs & {
        font-size: var(--font-size-small);
    }
}
