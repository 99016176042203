@import (reference) '@mc/wink/styles/tokens.less';

.root {
    color: var(--text-primary-color);
    background-color: var(--bg-lvl-1-color);
    max-width: var(--readable-max-width);
    padding: var(--spacing-3) var(--spacing-4);
    box-shadow: var(--elevation-lvl-3);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--spacing-3);
    // Re-enable clicks since they were removed on the ToastTunnel
    pointer-events: auto;
    border-radius: var(--border-overlay-radius);
}

.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    > * + * { margin-left: var(--spacing-4); }
}
.icon {
    svg {
        vertical-align: text-top;
    }
}
.content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
.copy {
    font: var(--font-text-small);
}
