@import (reference) '@mc/wink/styles/tokens.less';
@import (reference) '@mc/wink/styles/mixins.less';

.popup {
    padding: @spacing-6;
    background: var(--bg-lvl-1-color);
    box-shadow: var(--elevation-lvl-1);
    border-radius: var(--border-overlay-radius);

    transition: transform 0.2s ease-in-out;
    @media (prefers-reduced-motion) {
        transition: none;
    }
}
