/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */


@import (reference) '@mc/wink/styles/tokens.less';
@panel-width: @base-unit * 88;
// COLOR PICKERS
// Overriding default styles of ColorPicker (react-colorful)
:global {
    .colorSpace {
        &.popoverColorSpace {
            width: calc(@panel-width - 50px);
            height: 120px;

            .react-colorful__saturation {
                max-width: 100%;
            }

            .react-colorful__hue {
                height: calc(@spacing-7 + 2px);
            }

            .react-colorful__hue-pointer {
                width: calc(@spacing-3 + 1px);
                height: calc(@spacing-4 + 1px);
            }
        }

        .react-colorful {
            width: 100%;
            height: 100%;
        }

        .react-colorful__saturation {
            border-radius: 0;
            border-bottom: 0;
            max-width: @base-unit * 78; // 312px
            height: @base-unit * 60; // 240px
        }

        .react-colorful__alpha,
        .react-colorful__hue {
            margin-top: @spacing-4;
            height: @base-unit * 3;
            border-radius: 0;
        }

        .react-colorful__saturation-pointer {
            width: @spacing-4;
            height: @spacing-4;
            border: @border-radius-lv1 solid var(--interactive-inverted-color);
            box-shadow: 0 0 0 @border-radius-lv1 var(--border-input-color);
        }

        .react-colorful__alpha-pointer,
        .react-colorful__hue-pointer {
            width: @spacing-3;
            height: @spacing-4;
            border: 1px solid var(--border-light-color);
            border-radius: @border-radius-lv1;
            box-shadow: var(--elevation-lvl-2);

            .react-colorful__pointer-fill {
                // Important override because fill is set inline
                background-color: var(--bg-lvl-1-color) !important;
            }
        }

        // Focus styles
        .react-colorful__interactive {
            height: 100%;
            &:focus {
                outline: 2px var(--interactive-color) solid;
                outline-offset: 1px;
            }
        }

        // Hide Hue slider
        &.hideAlpha .react-colorful__alpha {
            display: none;
        }
    }
}
