@import (reference) '@mc/wink/styles/tokens.less';

@cell-size: @base-unit * 10;

.root {
    width: auto;
    max-width: @cell-size * 7;

}

.month {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: @spacing-4;
}

.calendar {
    font: var(--font-text-small);
    border-collapse: separate;
    border-spacing: 0;
    margin: 0 auto;

    th {
        font-weight: @font-weight-bold;
    }

    th,
    td {
        width: @cell-size;
        height: @cell-size;

        button {
            display: block;
            width: @cell-size;
            height: @cell-size;
            border: none;
            background-color: transparent;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0 0 0 2px transparent;
            transition:
                box-shadow 50ms ease-in-out 0s,
                background-color 50ms ease-in-out 0s;

            &[aria-disabled="true"] {
                color: var(--disabled-color);
                cursor: default;

                &:hover {
                    background-color: transparent;
                }
            }

            &:focus {
                outline: none;
                box-shadow: inset 0 0 0 2px var(--interactive-color), inset 0 0 0 4px var(--bg-lvl-1-color);
            }

            &:hover {
                background-color: var(--bg-interactive-color);
            }

            &:active:not([aria-disabled="true"]) {
                box-shadow: 0 0 0 2px var(--bg-lvl-1-color), inset 0 0 0 2px var(--interactive-color);
                background-color: var(--interactive-color);
                color: var(--text-secondary-color);
                font-weight: @font-weight-bold;
            }

            &[aria-pressed='true']:not([aria-disabled="true"]) {
                background-color: var(--interactive-color);
                color: var(--interactive-inverted-color);
                font-weight: @font-weight-bold;
            }
        }
    }
}

.isWithinRange {
    background-color: var(--bg-lvl-3-color);
}
