@import (reference) '@mc/wink/styles/tokens.less';
@import (reference) '@mc/wink/styles/mixins.less';

.root {
    max-width: var(--readable-max-width);
    vertical-align: middle;

    fieldset > & {
        font: var(--font-text-small);
    }

    .selectWrapper {
        position: relative;
    }

    .trigger,
    select {
        .mcds-boxed-input();

        min-width: var(--select-min-width);
        max-width: var(--select-max-width);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        cursor: pointer;
        // Use extra padding-right to clear space for the dropdown indicator svg
        padding-right: var(--spacing-7);

        // For button triggers
        text-align: left;

        // Mobile Safari workaround
        appearance: none;

        &:not([disabled]):not([readonly]):hover {
            background-color: var(--bg-interactive-color);
            border: 1px solid var(--border-input-color-hover);
            transition: background-color 50ms @animation-curve;
        }

        &[disabled] + .indicator svg {
            fill: var(--disabled-color);
        }

        // Firefox changes the <option> background based on prefers-color-scheme setting. If `dark`
        // is selected our default select text style becomes unreadable. Configuring a background
        // color for <option> progressively enhances the styles without blocking us from our own
        // dark mode efforts.
        option {
            background-color: var(--bg-lvl-1-color);
        }
    }

    .inputTrigger {
        cursor: text;
    }
}

.indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    padding: 0 @spacing-2;

    svg {
        fill: var(--icon-fill, var(--brand-peppercorn));
    }
}

.before {
    display: flex;
    justify-content: space-between;

    // Add bottom margin to `.before`'s direct children. If elements before the
    // input are visually empty, no margin should display before the input.
    > * {
        margin-bottom: @spacing-2;
    }
}

// Add top margin to elements after the input. If no elements exist after the
// input, no margin should display after the input.
.after {
    margin-top: @spacing-2;
}

.secondary {
    color: var(--text-secondary-color);
}

.error select,
.error .trigger {
    border-color: var(--border-error-color);

    &:focus {
        outline-color: var(--border-error-color);
    }
}

.errorMessage {
    color: var(--text-error-color);

    a {
        color: inherit;
    }
}

