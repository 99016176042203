@import (reference) '@mc/wink/styles/tokens.less';

.root {
    position: relative;
    display: inline-flex;
    justify-content: center;
}

.triggerIconWithLabel {
    svg {
        //Adding 4px to remove the 4px margin right under Button-container svg style
        margin-right: calc(-1rem * var(--font-ratio) + 4px);
        transition: transform 0.1s ease-in-out;
        @media (prefers-reduced-motion) {
            transition: none;
        }
    }

    &[aria-expanded='true'] {
        svg {
            transform: rotate(180deg);
        }
    }
}

.popup {
    z-index: var(--z-modal);
    transition: transform 0.2s ease-in-out;
    @media (prefers-reduced-motion) {
        transition: none;
    }
}

.menu {
    box-shadow: var(--elevation-lvl-1);
    background-color: var(--bg-lvl-1-color);
    // Make enough space so that big action lists in the middle of the screen
    // don't get their contents cut off.
    max-height: calc(50vh - 4em);
    overflow-y: auto;
    border-radius: var(--border-overlay-radius);
}

.item {
    position: relative;
    min-width: calc(var(--base-unit) * 60);
    max-width: var(--readable-max-width);
    padding: var(--spacing-3) var(--spacing-4);
    transition: background 50ms linear;

    &:hover {
        background: var(--bg-interactive-color);
        transition: background 50ms linear;

        .label {
            color: var(--interactive-hover-color);
        }
    }

    :global(.project-runway) &:hover {
        background: var(--color-container-background-tertiary);
    }

    &:focus-within {
        background: var(--bg-inverted-color);
        color: var(--text-inverted-color);

        .label,
        .description {
            color: inherit;
        }
    }
}

.disabled,
.disabled:hover {
    background: var(--bg-lvl-1-color);
    color: var(--disabled-color);
    cursor: not-allowed;

    .label,
    .description {
        color: inherit;
        cursor: inherit;
    }
}

.label {
    display: flex;
    align-items: center;

    width: 100%;
    font: var(--font-text-medium);
    overflow: hidden;
    color: var(--interactive-color);
    transition: color 50ms linear;

    // Spacing for label and icons
    > * + * {
        margin-left: var(--spacing-1);
    }

    // Handled by .item's :focus-within.
    &:focus {
        outline: none;
    }

    // Make the .label take up the whole space, including the .description.
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

.description {
    transition: color 50ms linear;
}

:not(.group) + .group {
    border-top: 1px solid var(--border-light-color);
}

.group:not(:last-child) {
    border-bottom: 1px solid var(--border-light-color);
    padding-bottom: var(--spacing-2);
}

.groupLabel {
    color: var(--text-secondary-color);
    font: var(--font-text-small);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    padding: var(--spacing-4) var(--spacing-4) var(--spacing-3);
    display: flex;
    word-break: break-word;
    position: sticky;
    top: 0;
    background-color: var(--bg-lvl-1-color);
    z-index: var(--z-base);
}

/******************
* PURPOSE
******************/

.passive {
    background-color: var(--bg-lvl-3-color);
    color: var(--text-primary-color);
    border-radius: var(--border-pill-radius);

    &:hover {
        background-color: var(--bg-lvl-4-color);
        color: var(--text-primary-color);
    }

    &:disabled,
    a&:not([href]) {
        opacity: var(--disabled-button-opacity);
        color: initial;
    }

    &.secondary {
        box-shadow:
            inset 0 0 0 var(--button-secondary-border-width)
            var(--bg-lvl-3-color);
        background-color: var(--button-secondary-background);
        text-decoration: none;

        &:hover {
            background-color: var(--bg-lvl-3-color);
            box-shadow:
                inset 0 0 0 var(--button-secondary-border-width)
                var(--brand-tuna); // no theming variable for tuna
        }
    }

    &.tertiary {
        background-color: var(--button-secondary-background);
        text-decoration: none;

        &:hover {
            background-color: var(--bg-lvl-3-color);
        }
    }

    &.tertiary {
        background-color: var(--button-secondary-background);
        text-decoration: none;

        &:hover {
            background-color: var(--bg-lvl-3-color);
        }
    }
}
