@import (reference) '@mc/wink/styles/tokens.less';

.container {
    background-color: var(--bg-lvl-2-color);
    padding: @spacing-6;

    .button {
        background-color: transparent;
    }
}

.containerWidth {
    max-width: @readable-max-width;
}

/** Manually hiding input. wink-visually-hidden class wasn't
working appropriately with :not(:focus) and :not(:active) because of styles needed below */
.fileinput {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
}

/** Cloning Secondary button styles.*/
.upload {
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    font: @font-weight-bold var(--font-text-small);
    // Line-height for button height
    line-height: @base-unit * 11;
    white-space: nowrap;
    padding: 0 @base-unit * 8;
    user-select: none;
    color: var(--interactive-color);
    box-shadow: inset 0 0 0 1px var(--interactive-color);
    border-radius: var(--border-pill-radius);
    text-decoration: none;
    transition:
        border @duration-base ease-in-out 0s,
        color @duration-base ease-in-out 0s,
        background-color @duration-base ease-in-out 0s;
    cursor: pointer;
}

.upload:hover {
    color: var(--interactive-hover-color);
    box-shadow: inset 0 0 0 1px var(--interactive-hover-color);
    background-color: var(--bg-interactive-color);
}


.fileinput:focus + .upload {
    outline: @base-unit * 0.5 solid var(--border-interactive-color);
    outline-offset: 1px;
}

