

/*------------------------------------*\
    #DetailList Root
\*------------------------------------*/

.root {
    /*
      Created a custom grid since GridLayout grid-template-columns
      property value cannot be override.
    */
    display: grid;
    grid-template-columns: minmax(max-content, auto) 1fr;
    gap: var(--spacing-1) var(--spacing-6);
}

/*------------------------------------*\
    #Title
\*------------------------------------*/

.title {
    /*  Force title text wrapping at a certain width. */
    max-width: 20rem;
    color: var(--text-secondary-color);
}
