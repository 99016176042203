
.list {
    max-width: var(--readable-max-width);
}

.listItem {
    break-inside: avoid;
}

.multiColumn {
    column-count: 2;
    column-gap: var(--spacing-8);
}

.large {
    font: var(--font-text-large);
}

.large-secondary {
    font: var(--font-text-large);
    color: var(--text-secondary-color);
}

.large-bold {
    font: var(--font-text-large);
    font-weight: var(--font-weight-bold);
}


/*  MEDIUM */
.medium {
    font: var(--font-text-medium);
}

.medium-secondary {
    font: var(--font-text-medium);
    color: var(--text-secondary-color);
}

.medium-bold {
    font: var(--font-text-medium);
    font-weight: var(--font-weight-bold);
}


/*  SMALL */
.small {
    font: var(--font-text-small);
}

.small-secondary {
    font: var(--font-text-small);
    color: var(--text-secondary-color);
}

.small-bold {
    font: var(--font-text-small);
    font-weight: var(--font-weight-bold);
}
