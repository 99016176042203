
.root {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: var(--z-menus);

    /*  Clicks pass through the fixed element to its children */
    pointer-events: none;
}
