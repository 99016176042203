@import (reference) '@mc/wink/styles/tokens.less';

.dialog {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: @z-modal;
}
