@import (reference) '@mc/wink/styles/tokens.less';

@modal-width-xs: 400px;
@modal-width-sm: 658px;
@modal-width-md: 856px;
@modal-width-lg: 1334px;

@modal-content-maxHeight: 804px;

// Styles for dialog container
.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--bg-underlay-color);
}

.root {
    background: var(--bg-lvl-1-color);
    box-shadow: var(--elevation-lvl-3);
    border-radius: var(--border-card-radius);
    position: relative;
    width: 90%;
    margin: 0 auto;

    max-height: 90vh;
    display: flex;
    flex-direction: column;

    // Size variants
    &.xs {
        max-width: @modal-width-xs;
    }
    &.sm {
        max-width: @modal-width-sm;
    }
    &.md {
        max-width: @modal-width-md;
    }
    &.lg {
        max-width: @modal-width-lg;
    }
}

// Modal Close Button

.closeButton {
    position: absolute;
    top: @spacing-3;
    right: @spacing-3;
    z-index: @z-nav;
}

// Modal Body

.body {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    font: var(--font-text-medium);
    max-height: @modal-content-maxHeight;
}

// here be dragons until we can use form inside the Modal body
.root > form {
    min-height: 0;
}

/*
 *
 * Note:
 * Compound components styles are in this file due to using
 * adjacent sibling combinators for .content and .sideView
 *
*/

// Modal Header

.header {
    width: 100%;
    padding: @spacing-5;
    border-bottom: 1px solid var(--border-light-color);
}

.title {
    font: @font-weight-bold var(--font-text-medium);
    // Specific to modals to center align title
    line-height: @line-height-reset;
    margin: 0 auto;
    width: 90%;
    text-align: center;
}

:global(.project-runway) {
    .title {
        font: var(--font-text-medium);
        font-weight: var(--font-weight-heading);
    }
}
// Modal Content

.content {
    padding: @spacing-8;
}

// Modal Side View

.sideView {
    flex-grow: 1;
    padding: @spacing-8 @spacing-7;
    background-color: var(--bg-lvl-2-color);
    border-radius: 0 var(--border-card-radius) var(--border-card-radius) 0;
}

// Modal Footer

.footer {
    border-top: 1px solid var(--border-light-color);
    padding: @spacing-3 @spacing-4;
    font: var(--font-text-medium);
    text-align: right;
}

// scroll lock

.lockScroll {
    height: 100vh;
    overflow: hidden;
}
