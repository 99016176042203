@import (reference) "@mc/wink/styles/tokens.less";
@import (reference) '@mc/wink/styles/mixins.less';

.root {
    transition:
        box-shadow var(--duration-base) ease-in-out 0s,
        background-color var(--duration-base) ease-in-out 0s;
    align-items: center;
    background-color: transparent;
    border-radius: 100%;
    cursor: pointer;
    display: inline-flex;
    height: 32px;
    justify-content: center;
    line-height: normal;
    margin: 0;
    overflow: hidden;
    user-select: none;
    vertical-align: middle;
    width: 32px;

    &:not([disabled]):hover {
        background-color: var(--bg-interactive-color);
    }

    // Override default link styles.
    a& {
        color: inherit;
    }

    &:focus,
    &:active,
    &[aria-pressed="true"] {
        box-shadow: 0 0 0 2px var(--border-interactive-color);
        // High-contrast mode
        outline: 2px solid transparent;
    }

    &[disabled] {
        opacity: 0.3;
        cursor: not-allowed;
    }

    .button-animations();
}
