/* File allow-listed for globally scoped CSS. Reach out to #fep-team with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */


:root {
    /*  Variants */
    --badge-bg--default: var(--brand-parsnip);
    --badge-bg--active: var(--brand-cornflower);
    --badge-bg--new: var(--brand-radish);
    --badge-bg--success: var(--brand-cucumber);
    --badge-bg--warning: var(--brand-squash);
    --badge-bg--error: var(--brand-yam);
    --badge-text-color: var(--text-primary-color);
    --badge-border: 0 solid transparent;
    --badge-font-weight: var(--font-weight-normal);
    --badge-text-transform: none;
}

:global(.project-runway) {
    --badge-bg--default: var(--bg-info-color);
    --badge-bg--active: var(--bg-active-color);
    --badge-bg--new: var(--bg-status-color);
    --badge-bg--success: var(--bg-success-color);
    --badge-bg--warning: var(--bg-warning-color);
    --badge-bg--error: var(--bg-error-color);
}

.root {
    display: inline-block;
    font: var(--font-text-small);
    font-weight: var(--badge-font-weight);
    text-transform: var(--badge-text-transform);
    padding: calc(var(--spacing-1) / 2) var(--spacing-2);
    color: var(--badge-text-color);
    border-radius: var(--border-badge-radius);
    border: var(--badge-border);
}

.default { background-color: var(--badge-bg--default); }
.new { background-color: var(--badge-bg--new); }
.active { background-color: var(--badge-bg--active); }
.success { background-color: var(--badge-bg--success); }
.warning { background-color: var(--badge-bg--warning); }
.error { background-color: var(--badge-bg--error); }
