.root {
    --readable-max-width: 312px;

    /*
     --border-color fallback is there for project runway when the
     xp.project_runway_design_foundations flag is off
    */
    --border-input-color: var(--color-input-border-primary, var(--border-color));
    --input-font-size: var(--font-size-small);
    --icon-fill: var(--brand-peppercorn-65);
    --select-min-width: calc(var(--base-unit) * 58);
    --select-max-width: calc(var(--base-unit) * 88);
    --input-padding-bottom: var(--base-unit);
    --input-padding-top: var(--base-unit);
    --input-padding-left: calc(var(--base-unit) * 2);
    --input-padding-right: calc(var(--base-unit) * 7.5);
    --indicator-right-position: calc(-1 * var(--base-unit));
}

.smallMenu {
    --readable-max-width: 156px;
    --select-min-width: calc(var(--base-unit) * 26);
    --select-max-width: calc(var(--base-unit) * 42);
}

.badge {
    @media screen and (--mq-max-width-phone) {
        display: none;
    }
}

.menuBadgeContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-4);
}
