
.root {
    display: inline-block;
    padding: 0;
}


.heading {
    display: inline-flex;
    align-items: center;
    padding: 0 var(--spacing-2) 0 0;
    color: var(--interactive-color);
    font-weight: var(--font-weight-bold);
    outline: 2px solid transparent;
    transition:
        background-color var(--animation-speed-fast) var(--animation-curve),
        outline var(--animation-speed-fast) var(--animation-curve),
        border-radius var(--animation-speed-fast) var(--animation-curve);

    svg {
        margin-right: var(--spacing-1);
        transition: transform var(--animation-speed-fast) var(--animation-curve);
    }

    &[aria-expanded="true"] svg {
        transform: rotate(180deg);
    }

    &:hover {
        cursor: pointer;
        background-color: var(--bg-interactive-color);
        outline: 2px solid var(--bg-interactive-color);
        color: var(--interactive-hover-color);
        border-radius: var(--border-pill-radius);
    }

    &:focus {
        outline: 2px solid var(--border-interactive-color);
        border-radius: var(--border-pill-radius);
    }
}

.body {
    /*  Line up the body content with the chevron icon, plus the spacing between */

    /*  the chevron and the heading. */
    margin-left: calc(var(--spacing-6) + var(--spacing-1));
    margin-bottom: var(--spacing-3);
}
