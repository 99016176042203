@import (reference) '@mc/wink/styles/tokens.less';

/*************************
*  Tooltip body
*************************/

.tooltip {
    pointer-events: none;
    z-index: @z-modal;
    padding: @spacing-3;
    font: var(--font-text-small);
    background: @brand-peppercorn;
    border-radius: var(--border-overlay-radius);
    color: @brand-coconut;
    box-shadow: var(--elevation-lvl-1);
    max-width: @readable-max-width / 2;

    transition: transform 0.2s ease-in-out;
    @media (prefers-reduced-motion) {
        transition: none;
    }
}

/*************************
* Arrow
*************************/

// Make sure the arrow size is odd. This will help pixel precision.
@arrow-size: @spacing-2 - 1;

.arrow {
    pointer-events: none;
    z-index: -1;
    // Intentional: We want this to be double the border.
    width: @arrow-size * 2;
    height: @arrow-size * 2;

    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: @arrow-size solid transparent;
    }

    // Arrow directions

    [data-popup-arrow-placement^='top'] > & {
        top: 100%;
        &::before {
            border-top-color: @brand-peppercorn;
        }
    }

    [data-popup-arrow-placement^='bottom'] > & {
        bottom: 100%;
        &::before {
            border-bottom-color: @brand-peppercorn;
        }
    }

    [data-popup-arrow-placement^='right'] > & {
        right: 100%;
        &::before {
            border-right-color: @brand-peppercorn;
        }
    }

    [data-popup-arrow-placement^='left'] > & {
        left: 100%;
        &::before {
            border-left-color: @brand-peppercorn;
        }
    }
}
