@import (reference) '@mc/wink/styles/tokens.less';

@checkmark-size: @base-unit * 6;

.root {
    display: flex;

    > * + * {
        margin-left: @spacing-4;
    }
}

.text {
    max-width: @readable-max-width;
}

.description {
    color: var(--text-secondary-color);
}

.label {
    &Disabled {
        color: var(--disabled-color);
    }
}

.input {
    // adding 1px to the width/height for buffer around input icon to avoid cut-off when zooming in browser
    width: @checkmark-size + 1;
    height: @checkmark-size + 1;
    overflow: hidden;
    margin: 0;
    flex-shrink: 0;
    border: 1px solid var(--border-input-color);
    border-radius: 50%;
    appearance: none;
    outline: none;

    &:checked {
        border-color: var(--interactive-color);

        &::before {
            content: '';
            background-color: var(--interactive-color);
            border-radius: 50%;
            border: 5px solid var(--bg-lvl-1-color);
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &:focus {
        border-color: var(--interactive-hover-color);
        border-width: 2px;
    }

    &:checked:focus {
        border-color: var(--interactive-hover-color);
        border-width: 3px;

        &::before {
            border-width: 4px;
        }
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: var(--bg-lvl-3-color);
        border-color: var(--disabled-border-color);
        &:checked {
            &::before {
                // In this scenario, background-color is the inner circle
                //  and border-color is the radio's background fill
                // This is due to how we create the selected circle
                background-color: var(--disabled-border-color);
                border-color: var(--bg-lvl-3-color);
            }
        }
    }
}

// Error messaging
.error .input {
    border-color: var(--text-error-color);

    &:focus {
        outline: 2px solid var(--text-error-color);
        outline-offset: 1px;
    }
}

.errorMessage {
    color: var(--text-error-color);

    a {
        color: inherit;
    }
}
