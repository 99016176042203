
.root {
    display: flex;
    justify-content: space-between;
}

.content {
    max-width: calc(var(--base-unit) * 80);
    margin-right: var(--spacing-7);

    /* Necessary for long labels, see: https://css-tricks.com/flexbox-truncated-text/ */
    min-width: 0;
}

.helpText {
    color: var(--text-secondary-color);
}

.toggle {
    align-items: center;
    background: var(--bg-toggle-color);
    border-radius: var(--spacing-5);
    box-shadow: 0 0 0 1px var(--border-toggle-color);
    color: var(--text-inverted-color);
    display: flex;
    height: var(--spacing-6);
    padding: 0 var(--base-unit);
    position: relative;
    transition: var(--background-transition), var(--box-shadow-transition);
    width: 44px;

    /*  For Windows High Contrast Mode */
    &:focus { outline: 1px solid transparent; }

    .knob { background-color: currentColor; }

    &[aria-pressed='false'] {
        &:focus { box-shadow: 0 0 0 2px var(--border-interactive-color); }

        .knob { right: 22px; }
    }

    &[aria-pressed='true'] {
        background: var(--bg-toggle-activated-color);
        box-shadow: none;
        line-height: 0;

        &:focus { box-shadow: 0 0 0 2px var(--border-dark-color); }
    }

    &:disabled {
        background-color: var(--bg-toggle-color);
        color: var(--brand-coconut-50);
        box-shadow: none;
        cursor: not-allowed;
    }

    .toggleGroup {
        display: flex;
        align-items: center;
        width: 44px;
    }
}

.knob {
    border-radius: 50%;
    height: 18px;
    pointer-events: none;
    position: absolute;
    transition:
        background-color var(--duration-base) ease-in-out,
        right var(--duration-base) ease-in-out;
    right: 3px;
    width: 18px;
}

/*  Temporary until tick small icon is added to wink-icons */
.tickIcon {
    fill: currentColor;
    height: var(--spacing-4);
    width: var(--spacing-4);
}

/*  Style when label is hidden */
.hideLabel {
    /*  Remove alignment on Toggle Buttons with no label */
    justify-content: flex-start;
    .content {
        margin-right: 0;
    }
}
