/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import (reference) '@mc/wink/styles/tokens.less';

:root {
    // local variables
    --swatch-border-color: var(--border-light-color);
    --swatch-size: @spacing-7;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: @spacing-4 0 @spacing-2;

    > * + * {
        margin-left: @spacing-2;
    }
}

.addBtn {
    // Overwrite iconButton styles
    svg {
        width: @spacing-4;
        height: @spacing-4;
    }
}

.item {
    // Hide remove button when not focusing within or hovering over swatch
    &:not(:focus-within):not(:hover) .itemRemoveBtn {
        margin-left: auto;
        opacity: 0;
    }

    &:focus-within,
    &:hover {
        --swatch-border-color: var(--border-dark-color);
        background-color: var(--bg-lvl-2-color);
    }
}

.checkerBg {
    background-color: var(--bg-lvl-1-color);
    background-image:
        linear-gradient(
            45deg,
            var(--bg-lvl-3-color) 25%,
            transparent 25%
        ),
        linear-gradient(
            -45deg,
            var(--bg-lvl-3-color) 25%,
            transparent 25%
        ),
        linear-gradient(
            45deg,
            transparent 75%,
            var(--bg-lvl-3-color) 75%
        ),
        linear-gradient(
            -45deg,
            transparent 75%,
            var(--bg-lvl-3-color) 75%
        );
    background-size: @spacing-2 @spacing-2;
    background-position: 0 0, 0 @spacing-1, @spacing-1 (-@spacing-1), -@spacing-1 0;
    width: var(--swatch-size);
    height: var(--swatch-size);
}

.button {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;

    // Focus is handled via focus-within
    &:focus {
        outline: none;
    }

    > * + * {
        margin-left: @spacing-4;
    }

    .checkerBg {
        display: block;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        border: 1px solid var(--border-light-color);
    }
}

.button:hover .checkerBg,
.item:focus-within .checkerBg {
    box-shadow: 0 0 0 2px var(--swatch-border-color);
    outline: 1px solid transparent;
}

.color {
    position: absolute;
    display: block;
    width: var(--swatch-size);
    height: var(--swatch-size);

    // Shift .swatchColor left to reveal a checkerboard background if the color
    // is slightly transparent.
    &.isTransparent {
        transform: translate(-50%, 0);
    }
}

// Swatches List
.list {
    --swatch-size: @spacing-6;

    .item {
        padding: @spacing-2 @spacing-4;
        display: flex;
    }

    // This section creates a focus outline around the swatch button. position:
    // relative on .item constrains the dimensions of the ::after
    // pseudo-element. Position relative on neighbors of .button will paint
    // them on top of the pseudo-element. If we don't do this, the
    // pseudo-element overlaps the neighbors, rendering them unclickable.
    .item,
    .button ~ * {
        position: relative;
    }

    .button {
        // Expand the button's clickable area to the whole .item.
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        // Whenever the button is focused, add an outline to the ::after
        // pseudoelement.
        &:focus::after {
            box-shadow: inset 0 0 0 2px var(--interactive-color);
            outline: 1px solid transparent;
        }
    }
}

// Swatches Grid
.grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: @spacing-2;

    .item:focus-within {
        --swatch-border-color: var(--border-interactive-color);
    }
}
