
.root {
    display: flex;
    align-items: flex-start;
}

.text {
    max-width: var(--readable-max-width);
}

.label {
    &Disabled {
        color: var(--disabled-color);
    }
}

.description {
    color: var(--text-secondary-color);
}

.input {
    appearance: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    margin: 0;
    width: calc(var(--base-unit) * 6);
    height: calc(var(--base-unit) * 6);
    border: 1px solid var(--border-input-color);
    border-radius: var(--border-input-radius);

    transition: background-color 0.2s linear;

    /*  Used for value="true" */

    /*  We can swap this with our svg tick mark in future iterations if preferred */
    &::after {
        content: '';
        display: block;
        visibility: hidden;

        /*  The border must match the base background. */
        border-left: 2px solid var(--bg-lvl-1-color);
        border-bottom: 2px solid var(--bg-lvl-1-color);
        margin: 6px 5px;
        height: 6px;
        width: calc(var(--base-unit) * 3);
        opacity: 0;
        transform: rotate(-45deg);
        transition: opacity 0.2s linear;
    }

    /*  Used for indeterminate="true" */

    /*  We can swap this with our svg minus in future iterations if preferred */
    &::before {
        content: '';
        display: none;

        /*  The border must match the base background. */
        border-bottom: 2px solid var(--bg-lvl-1-color);
        margin: 6px 5px;
        height: 6px;
        width: calc(var(--base-unit) * 3);
        opacity: 0;
        transition: opacity 0.2s linear;
    }

    &:checked {
        border-color: var(--interactive-color);
        background-color: var(--interactive-color);
        &::after {
            opacity: 1;
            visibility: visible;
        }
    }

    &[indeterminate=true] {
        border-color: var(--interactive-color);
        background-color: var(--interactive-color);
        &::before {
            display: block;
            opacity: 1;
        }
    }

    &:focus {
        outline: 2px solid var(--border-interactive-color);
        outline-offset: 1px;
    }

    &[disabled],
    &[disabled]::after {
        background-color: var(--bg-lvl-3-color);
        border-color: var(--disabled-border-color);
        cursor: not-allowed;
    }

    &:not(.noMargin) {
        margin-right: var(--spacing-4);
    }
}

/*  Error messaging */
.error .input {
    border-color: var(--text-error-color);

    &:focus {
        outline: 2px solid var(--text-error-color);
        outline-offset: 1px;
    }
}

.errorMessage {
    color: var(--text-error-color);

    a {
        color: inherit;
    }
}
