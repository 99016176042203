/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import (reference) '@mc/wink/styles/tokens.less';

:root {
    // Manually declaring variables because SiteAlert is under a dark mode class
    // which causes other theming variables to automatically invert.
    // Maybe create a new background level theming variable for peppercorn?
    --number-background: @brand-coconut;
    --number-color: @brand-peppercorn;
}

.root {
    padding: @spacing-4;
    background-color: var(--bg-lvl-1-color);
    color: var(--text-primary-color);
    width: 100%;
    * {
        z-index: @z-preview;
    }
}

.number {
    border-radius: 50%;
    width: @base-unit * 5;
    height: @base-unit * 5;
    line-height: @base-unit * 5;
    text-align: center;
    background-color: var(--number-background);
    color: var(--number-color);
    font-weight: @font-weight-bold;
    font-size: var(--font-size-small);
}

.message:not(:first-of-type) {
    padding-top: @spacing-4;
    border-top: 1px solid var(--border-light-color);
}
