@import (reference) './tokens.less';

/*********************************************
    Mixins
    This file should be used for mixins that can easily be reused throughout the
    app.
*********************************************/

.smooth-scroll() {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}


// Spacing

@spacing-map:
    1 @spacing-1,
    2 @spacing-2,
    3 @spacing-3,
    4 @spacing-4,
    5 @spacing-5,
    6 @spacing-6,
    7 @spacing-7,
    8 @spacing-8,
    9 @spacing-9,
    10 @spacing-10,
    11 @spacing-11;

.layout-spacing(@body, @i: 1) when (@i <= length(@spacing-map)) {
    @pair: extract(@spacing-map, @i);
    @size: extract(@pair, 1);
    @value: extract(@pair, 2);

    @body();

    .layout-spacing(@body, @i + 1);
}

.mcds-boxed-input-focus() {
    outline: 2px solid var(--border-interactive-color);
    outline-offset: 1px;
    border: 1px solid var(--border-input-color-hover);

    // Mobile Safari workaround
    box-shadow: inset 0 -2px 0 transparent;
}

.mcds-boxed-input-disabled() {
    cursor: not-allowed;
    background-color: transparent;
    color: var(--disabled-color);
    border-color: var(--disabled-border-color);
}

/**
 *  Style for boxed inputs like text inputs, selects, and textareas.
 *
 *  Meant for internal use within components.
 */
.mcds-boxed-input() {
    font: inherit;
    font-size: var(--input-font-size, @font-size-medium);
    line-height: @line-height-medium;
    width: 100%;
    display: block;
    padding-top: var(--input-padding-top, 10px);
    padding-bottom: var(--input-padding-bottom, 10px);
    padding-left: var(--input-padding-left, @spacing-4);
    padding-right: var(--input-padding-right, @spacing-4);
    border: 1px solid var(--border-input-color);
    color: var(--text-primary-color);
    background-color: var(--bg-lvl-1-color);
    border-radius: var(--border-input-radius);
    box-sizing: border-box;
    transition: box-shadow 200ms ease-in-out, border-color 200ms ease-in-out, background-color 150ms @animation-curve;
    // Mobile Safari workaround
    background-clip: padding-box;

    &:not([disabled]):focus {
        .mcds-boxed-input-focus();
    }

    &[disabled] {
        .mcds-boxed-input-disabled();
    }

    &[readonly] {
        background-color: transparent;
    }

    &::placeholder {
        color: var(--text-secondary-color);
    }

    &::-ms-clear {
        display: none;
    }
}


// For button-like animations

.button-animations() {
    transition: transform 0.2s ease-in-out 0s;
    &:active {
        transform: translateY(4px);

        &:focus {
            outline: 0;
            outline-offset: 0;
        }

        @media (prefers-reduced-motion) {
            transform: translateY(0);
        }
    }
}

