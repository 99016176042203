@import (reference) '@mc/wink/styles/tokens.less';
@import (reference) '@mc/wink/styles/mixins.less';

.root {
    max-width: @readable-max-width;

    fieldset > & {
        font: var(--font-text-small);
    }

    textarea {
        .mcds-boxed-input();
        min-height: 5.5em;

        &:not([disabled]):not([readonly]):hover {
            border-color: var(--border-dark-color);
        }
    }
}

.before {
    display: flex;
    justify-content: space-between;

    // Add bottom margin to `.before`'s direct children. If elements before the
    // input are visually empty, no margin should display before the input.
    > * {
        margin-bottom: @spacing-2;
    }
}

// Add top margin to elements after the input. If no elements exist after the
// input, no margin should display after the input.
.after {
    margin-top: @spacing-2;
}

.secondary {
    color: var(--text-secondary-color);
}

.error textarea {
    border-color: var(--text-error-color);

    &:focus {
        outline-color: var(--text-error-color);
    }
}

.errorMessage {
    color: var(--text-error-color);

    a {
        color: inherit;
    }
}
