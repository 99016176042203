@import (reference) '@mc/wink/styles/tokens.less';

/* stylelint-disable css-modules/no-global-scoped-selector */
:root {
    --tag-line-height: calc(var(--font-ratio) * 1.6rem);
}

.root {
    display: inline-flex;
    background-color: var(--bg-lvl-3-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-badge-radius);
    align-items: center;
    transition: background-color 150ms var(--animation-curve);

    &.interactive {
        &:hover {
            background-color: var(--bg-lvl-4-color);
            transition: background-color 50ms var(--animation-curve);
        }

        &:focus {
            outline: 2px solid var(--border-interactive-color);
            outline-offset: 1px;
        }
    }

    &.interactive:not(.dismissible) {
        color: var(--interactive-hover-color);
        font-weight: var(--font-weight-bold);
        text-decoration: underline;
    }
}

:global(.project-runway) {
    .root {
        background-color: var(--color-ui-tertiary);
    }
    .interactive:hover {
        background-color: var(--color-ui-tertiary-active);
    }
}

.small {
    padding: var(--spacing-1) var(--spacing-2);
    font: var(--font-text-small);
    // Tag line-heights differ from small text
    line-height: var(--tag-line-height);
}

.large {
    padding: var(--spacing-1) var(--spacing-4);
    font: var(--font-text-medium);
}

.x-large {
    padding: var(--spacing-1) var(--spacing-2);
    font: var(--font-text-medium);

    .crossIcon {
        height: calc(var(--base-unit) * 6);
        width: calc(var(--base-unit) * 6);
        margin-left: var(--spacing-4);
    }
}

.dismissible {
    // Optically balances the close icon.
    padding-right: var(--spacing-2);
}

.crossIcon {
    height: calc(var(--base-unit) * 4);
    width: calc(var(--base-unit) * 4);
    margin-left: var(--spacing-1);
}
