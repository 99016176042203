@import (reference) '@mc/wink/styles/tokens.less';

.root {
    color: var(--text-headline-primary-color);
    letter-spacing: var(--letter-spacing-heading);
}

.heading-1 {
    font: var(--font-heading-1);
    font-weight: var(--font-heading-weight);
}

.heading-2 {
    font: var(--font-heading-2);
    font-weight: var(--font-heading-weight);
}

.heading-3 {
    font: var(--font-heading-3);
    font-weight: var(--font-heading-weight);
}

.heading-4 {
    font: var(--font-heading-4);
    font-weight: var(--font-heading-weight);
}

:global(.project-runway) {
    .heading-3 {
        font: var(--font-heading-5);
        font-weight: var(--font-heading-weight);
    }

    .heading-5 {
        font: var(--font-heading-5);
        font-weight: var(--font-heading-weight);
    }

    .heading-6 {
        font: var(--font-heading-6);
        font-weight: var(--font-heading-weight);
    }

    .heading-display {
        letter-spacing: var(--letter-spacing-display);
    }
}

.text-wrap {
    text-wrap: wrap;
}
.text-nowrap {
    text-wrap: nowrap;
}
.text-balance {
    text-wrap: balance;
}
.text-pretty {
    text-wrap: pretty;
}
