@import (reference) '@mc/wink/styles/tokens.less';

.root {
    --arrow-size: var(--spacing-3);
    background-color: var(--bg-lvl-1-color);
    // Use the filter: drop-shadow to apply a shadow to content with arrow indication instead of box-shadow.
    filter: drop-shadow(var(--elevation-lvl-1));
    border-radius: var(--border-overlay-radius);
    z-index: var(--z-modal);
    padding: var(--spacing-3);

    transition: transform 0.2s ease-in-out;
    @media (prefers-reduced-motion) {
        transition: none;
    }
}

:global(.project-runway) {
    .root {
        box-shadow: var(--elevation-lvl-3);
        filter: none;
    }
}

.arrowRoot {
    pointer-events: none;
    z-index: -1;
    // Intentional: We want this to be double the border.
    width: calc(var(--arrow-size) * 2);
    height: calc(var(--arrow-size) * 2);

    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: var(--arrow-size) solid transparent;
    }

    // Arrow directions
    [data-popup-arrow-placement^='top'] > & {
        top: 100%;
        &::before {
            border-top-color: var(--bg-lvl-1-color);
        }
    }

    [data-popup-arrow-placement^='bottom'] > & {
        bottom: 100%;
        &::before {
            border-bottom-color: var(--bg-lvl-1-color);
        }
    }

    [data-popup-arrow-placement^='right'] > & {
        right: 100%;
        &::before {
            border-right-color: var(--bg-lvl-1-color);
        }
    }

    [data-popup-arrow-placement^='left'] > & {
        left: 100%;
        &::before {
            border-left-color: var(--bg-lvl-1-color);
        }
    }
}

.body {
    max-width: calc(var(--readable-max-width) / 2);
}

.closeButton {
    float: right;
}
