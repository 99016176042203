@import (reference) '@mc/wink/styles/tokens.less';

/* stylelint-disable css-modules/no-global-scoped-selector */
:root {
    --box-shadow-offset: calc(var(--base-unit) * -1);
    --feedback-block-border-left: 0;
    --feedback-block-border-radius: 0;

    /* Background Colors */
    --feedback-block-bg-color: var(--bg-lvl-2-color);
    --feedback-block-error-bg-color: var(--bg-lvl-2-color);
    --feedback-block-warning-bg-color: var(--bg-lvl-2-color);
    --feedback-block-success-bg-color: var(--bg-lvl-2-color);
    --feedback-block-info-bg-color: var(--bg-lvl-2-color);

    /* Border Colors */
    --feedback-block-border-color: var(--border-light-color);
    --feedback-block-error-border-color: var(--feedback-block-border-color);
    --feedback-block-warning-border-color: var(--feedback-block-border-color);
    --feedback-block-success-border-color: var(--feedback-block-border-color);
    --feedback-block-info-border-color: var(--feedback-block-border-color);
}

:global(.project-runway) {
    --feedback-block-bg-color: var(--color-page-background-secondary);
    --feedback-block-error-bg-color: var(--color-page-background-secondary);
    --feedback-block-warning-bg-color: var(--color-page-background-secondary);
    --feedback-block-success-bg-color: var(--color-page-background-secondary);
    --feedback-block-info-bg-color: var(--color-page-background-secondary);
}

// General Styles
.root {
    background-color: var(--feedback-block-bg-color);
    padding: var(--spacing-5) var(--spacing-4);
    box-shadow: var(--box-shadow-offset) 0 0 0 var(--box-shadow-color);
    border: 1px solid var(--feedback-block-border-color);
    border-left:
        var(--feedback-block-border-left) solid
        var(--feedback-block-border-color);
    border-radius: var(--feedback-block-border-radius);

    // Variable updates for variants
    &.default {
        --box-shadow-color: var(--text-primary-color);
    }
    &.error {
        --box-shadow-color: var(--border-error-color);
        --feedback-block-border-color: var(--feedback-block-error-border-color);
        background-color: var(--feedback-block-error-bg-color);
    }
    &.warning {
        --box-shadow-color: var(--border-warning-color);
        --feedback-block-border-color: var(--feedback-block-warning-border-color);
        background-color: var(--feedback-block-warning-bg-color);
    }
    &.success {
        --box-shadow-color: var(--border-success-color);
        --feedback-block-border-color: var(--feedback-block-success-border-color);
        background-color: var(--feedback-block-success-bg-color);
    }
    &.info {
        --box-shadow-color: var(--border-info-color);
        --feedback-block-border-color: var(--feedback-block-info-border-color);
        background-color: var(--feedback-block-info-bg-color);
    }
}

// Icon
.icon svg {
    fill: var(--box-shadow-color);
    vertical-align: middle;
}

// Feedback Content
.content {
    width: 100%;
}

.copy {
    flex-basis: 0;
    flex-grow: 2;

    // Take text font-weight instead of bold
    a {
        font-weight: inherit;
    }
}
