@import (reference) '@mc/wink/styles/tokens.less';

.root {
    display: inline-flex;
    align-items: center;
    white-space: pre-wrap;
    font: var(--font-text-small);
    font-weight: var(--font-weight-bold);

    color: var(--interactive-color);
    background-color: transparent;
    box-shadow: 0 0 0 0 rgba(0, 124, 137, 0);
    border-radius: var(--border-pill-radius);
    text-decoration: none;
    line-height: calc(var(--font-size-h4));
    transition:
        background-color var(--animation-speed-slow) var(--animation-curve),
        box-shadow var(--animation-speed-slow) var(--animation-curve),
        color var(--animation-speed-slow) var(--animation-curve);

    &:focus {
        outline: calc(var(--base-unit) * 0.5) solid var(--border-interactive-color);
        outline-offset: var(--spacing-1);
    }

    &:hover {
        background-color: var(--bg-interactive-color);
        box-shadow: 0 0 0 6px var(--bg-interactive-color);
    }

    &:hover,
    &:focus,
    &:active {
        color: var(--interactive-hover-color);
        transition:
            background-color var(--animation-speed-fast) var(--animation-curve),
            box-shadow var(--animation-speed-fast) var(--animation-curve),
            color var(--animation-speed-fast) var(--animation-curve);
    }

    &[disabled] {
        pointer-events: none;
        color: var(--disabled-color);
        background-color: transparent;
    }
}
