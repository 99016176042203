@import (reference) '@mc/wink/styles/tokens.less';

.root {
    display: inline-block;
    box-shadow: none;
    outline: none;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-width: @base-unit * 12;
    position: relative;
    padding: 0 @spacing-1;
    // Copy native <select> cursor.
    cursor: default;
    vertical-align: bottom;
    line-height: @line-height-text;
    transition: background-color 150ms @animation-curve, border-radius 150ms @animation-curve;
    z-index: 0;

    /* Focus */
    &:focus-within {
        border-radius: var(--border-pill-radius);
        outline: 2px solid var(--border-interactive-color);
        outline-offset: 1px;
        // Mobile Safari workaround
        box-shadow: inset 0 -2px 0 transparent;
    }

    // Remove duplicate focus style for combobox. :focus-within on .root handles
    // the overall focus style.
    [role="combobox"]:focus {
        outline: none;
    }

    // Expand the combobox's click area to the whole component.
    [role="combobox"]::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    /* Hover */
    &:not([disabled]):hover {
        background-color: var(--bg-lvl-2-color);
        transition: background-color 50ms @animation-curve;
        border-radius: var(--border-pill-radius);
    }

    label {
        display: inline-block;
        font-weight: @font-weight-bold;
    }

    select {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        border: none;
        border-radius: 0;
        width: 100%;
        z-index: @z-base;
    }

    .selectedValue {
        display: flex;
        padding-left: @spacing-1;
        font-weight: @font-weight-bold;
        color: var(--interactive-color);
        max-width: @readable-max-width / 2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
    }

    .indicator {
        margin-left: @spacing-1;
        line-height: 0;
    }

    .indicator svg {
        fill: var(--interactive-color);
    }

    &.disabled {
        cursor: not-allowed;

        .selectedValue {
            color: var(--disabled-color);
        }

        .indicator svg {
            fill: var(--disabled-color);
        }
    }
}

.listbox {
    display: inline-flex;
}
