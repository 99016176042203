@import (reference) '@mc/wink/styles/tokens.less';

@thumb-color: var(--border-dark-color);
@thumb-color-active: var(--border-color);
@thumb-color-disabled: var(--bg-lvl-3-color);
@track-color: var(--bg-lvl-4-color);
@border-radius: @track-height / 2;
@thumb-size: 16px;
@thumb-click-size: 24px; // Accessibility area
@track-height: 6px;
@thumb-fake: 0 0 0 ((@thumb-size - @track-height) / 2) @thumb-color;
@thumb-fake-disabled: 0 0 0 ((@thumb-size - @track-height) / 2) @thumb-color-disabled;

.root {
    max-width: @readable-max-width;

    fieldset > & {
        font: var(--font-text-small);
    }

    output {
        color: var(--text-secondary-color);
    }

    input {
        cursor: pointer;
        appearance: none;
        width: 100%;
        cursor: pointer;
        background: transparent;
        box-sizing: border-box;
        // Mobile Safari workaround
        background-clip: padding-box;

        // https://css-tricks.com/sliding-nightmare-understanding-range-input/
        margin: 0;
        padding: 0;
        font-size: inherit;

        /*
        * Within -webkit- implementations of `<input type="range" />, there is a container
        * 'div' that we can target in order to adjust the clickable area for the slider without impacting the rest of the styles
        *
        * For -moz- implementations, there is no container div, and the `<input />` element itself is the container
        */

        // Webkit
        &::-webkit-slider-container {
            min-block-size: @thumb-click-size;
        }

        /*
        * Base thumb styles.
        *
        * The Slider's thumb is the little knob that users move around either
        * via mouse or arrow keys.
        *
        * There is some difficulty with styling thumbs. One big one is cross-
        * browser compatibility. Some browsers have the range input's width
        * include the thumb's width, others do not. So we make the thumb as
        * small as it possibly can be, then use box-shadow to fake the rest.
        *
        * Padding and margins on the input range also affect rendering depending
        * on the browser. It's kind of a mess. See this for more:
        * https://css-tricks.com/sliding-nightmare-understanding-range-input/
        */

        .thumb-base() {
            width: @track-height;
            height: @track-height;
            box-shadow: @thumb-fake;
            border: none;
            border-radius: 50%;
            background-color: @thumb-color;
            transition: box-shadow 0.2s linear;
        }

        // Webkit
        &::-webkit-slider-thumb {
            .thumb-base();
            -webkit-appearance: none;
            // Center thumb vertically
            margin-top: 0;
        }

        // Firefox
        &::-moz-range-thumb {
            .thumb-base();
        }

        // MSEdge
        &::-ms-thumb {
            .thumb-base();
            // Center thumb vertically (differently than -webkit-)
            margin-top: 0;
        }


        /**
         * Input interaction styles
         */

        // Hover styles
        &:hover:not(:disabled):not(:focus) {
            .thumb-hover() {
                box-shadow: @thumb-fake, 0 0 0 10px @thumb-color-active;
            }
            &::-webkit-slider-thumb { .thumb-hover(); }
            &::-moz-range-thumb { .thumb-hover(); }
            &::-ms-thumb { .thumb-hover(); }
        }

        // Focus styles
        &:focus {
            outline: none;

            .thumb-focus() {
                // For Windows High Contrast Mode
                outline: 2px solid transparent;
                box-shadow: @thumb-fake, 0 0 0 16px @thumb-color-active;
            }
            &::-webkit-slider-thumb { .thumb-focus(); }
            &::-moz-range-thumb { .thumb-focus(); }
            &::-ms-thumb { .thumb-focus(); }
        }

        // Disabled styles
        &:disabled {
            cursor: not-allowed;
            outline: none;

            .thumb-disabled() {
                background: @thumb-color-disabled;
                box-shadow: @thumb-fake-disabled;
            }
            &::-webkit-slider-thumb { .thumb-disabled(); }
            &::-moz-range-thumb { .thumb-disabled(); }
            &::-ms-thumb { .thumb-disabled(); }
        }

        /*
        * Base track styles.
        *
        * The Slider's track is the line that runs the length of the slider.
        * Note that we style the peppercorn bit via a separate div.
        */

        .track-base() {
            width: 100%;
            height: @track-height;
            background: @track-color;
            border-radius: @border-radius;
        }

        // Webkit
        &::-webkit-slider-runnable-track {
            .track-base();
        }

        // Firefox
        &::-moz-range-track {
            .track-base();
        }

        // MSEdge
        &::-ms-track {
            .track-base();
            background: transparent;
            border-color: transparent;
            color: transparent;
        }

        &::-ms-fill-lower,
        &:focus::-ms-fill-lower,
        &::-ms-fill-upper,
        &:focus::-ms-fill-upper {
            background: @track-color;
        }

        /**
         * Native tooltips.
         */
        &::-ms-tooltip { display: none; }
    }
}

.before {
    display: flex;
    justify-content: space-between;

    // Add bottom margin to `.before`'s direct children. If elements before the
    // input are visually empty, no margin should display before the input.
    > * {
        margin-bottom: @spacing-2;
    }
}

.rangeContainer {
    display: flex;
    align-items: center;
    width: 100%;

    > svg {
        width: 24px;
        height: 24px;
    }
}

.withAnchorIcons {
    margin: 0 @spacing-2;
}

.range {
    position: relative;
    display: block;
    width: 100%;
    height: @thumb-size;
    display: flex;
    align-items: center;
    margin: 0 (@thumb-size / 2);
}

.track {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    height: @track-height;
    background-color: @thumb-color;
    border-top-left-radius: @border-radius;
    border-bottom-left-radius: @border-radius;
}

.ticks {
    pointer-events: none;
    width: 100%;
    position: absolute;
    left: 0;
    height: @track-height;
    display: flex;
    justify-content: space-between;
    transform: translateY(@thumb-size);
}

.tick {
    background-color: var(--border-dark-color);
    width: @track-height;
    height: @track-height;
    border-radius: 50%;
    transform: translateY(-@thumb-size);
}

.helpText {
    margin-top: @spacing-2;
    color: var(--text-secondary-color);
}

.errorMessage {
    margin-top: @spacing-2;
    color: var(--text-error-color);
}
