@import (reference) '@mc/wink/styles/tokens.less';

.root {
    display: inline-flex;

}

.button {
    padding: 0 var(--spacing-4);
    border-radius: var(--border-pill-radius) 0 0 var(--border-pill-radius);
}

.actionList {
    margin-left: 1px;
    padding: 0 var(--spacing-2);
    border-radius: 0 var(--border-pill-radius) var(--border-pill-radius) 0;

    svg {
        transition: transform 0.1s ease-in-out;
        @media (prefers-reduced-motion) {
            transition: none;
        }
    }

    &[aria-expanded="true"] {
        svg {
            transform: rotate(180deg);
        }
    }
}

.secondary .button,
.secondary .actionList {
    box-shadow: 0 0 0 1px var(--interactive-color);

    &:hover,
    &:active {
        box-shadow: 0 0 0 1px var(--interactive-color);
    }

    &:focus {
        outline-offset: 2px;
    }

    &:disabled {
        box-shadow: 0 0 0 1px var(--disabled-border-color);
    }
}

/******************
* SIZE
******************/

/* small */

.small .button {
    padding: 2px var(--spacing-2) 2px var(--spacing-3);
    line-height: calc(var(--base-unit) * 5);
}

.small .actionList {
    line-height: calc(var(--base-unit) * 5);
}

.small .actionList svg {
    height: calc(var(--base-unit) * 4);
    width: calc(var(--base-unit) * 4);
}

/* medium */

.medium .button {
    padding: var(--spacing-2) var(--spacing-3) var(--spacing-2) var(--spacing-4);
    line-height: calc(var(--base-unit) * 5);
}

.medium .actionList {
    line-height: calc(var(--base-unit) * 5);
}

.medium .actionList svg {
    height: calc(var(--base-unit) * 4);
    width: calc(var(--base-unit) * 4);
}

/******************
* PURPOSE
******************/

.passive {
    background-color: var(--bg-lvl-3-color);
    color: var(--text-primary-color);


    &.button {
        border-right: 1px solid var(--brand-tuna); // no theming variable for tuna
    }

    &:hover {
        background-color: var(--bg-lvl-4-color);
        color: var(--text-primary-color);
    }

    &:disabled,
    a&:not([href]) {
        opacity: var(--disabled-button-opacity);
        color: initial;
    }

    &.secondary {
        background-color: var(--bg-lvl-1-color);
        margin-left: 0;
        box-shadow: 0 0 0 1px var(--bg-lvl-4-color);


        &:hover {
            background-color: var(--bg-lvl-3-color);
            box-shadow:
                inset 0 0 0 var(--button-secondary-border-width)
                var(--brand-tuna); // no theming variable for tuna
        }
    }

}
