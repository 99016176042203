@import (reference) '@mc/wink/styles/tokens.less';
@import (reference) '@mc/wink/styles/mixins.less';


.root {
    max-width: @readable-max-width;

    fieldset > & {
        font: var(--font-text-small);
    }

    input {
        /* Input styles are visually implemented on the inputWrapper */
        border: none;
        flex: 1;
        width: 100%;
        outline: none;
        padding: var(--input-padding-top, 10px) 0 var(--input-padding-bottom, 10px) 0;
        background-color: transparent;

        &::placeholder {
            color: var(--text-secondary-color);
        }

        &::-ms-clear {
            display: none;
        }
    }
}

.inputWrapper {
    .mcds-boxed-input();
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
    padding: 0;

    &:focus-within {
        .mcds-boxed-input-focus();
    }

    &:not(.disabledInput):not(.readOnlyInput):hover {
        border-color: var(--border-dark-color);
    }
}

.inputWrapper > :first-child {
    padding-left: var(--input-padding-left, @spacing-4);
}

.inputWrapper > :last-child {
    padding-right: var(--input-padding-right, @spacing-4);
}

.inputPrefix {
    padding-right: @spacing-1;
}
.inputSuffix {
    padding-left: @spacing-1;
}

.disabledInput,
.disabledInput input {
    .mcds-boxed-input-disabled();
}

.readOnlyInput {
    background-color: transparent;
}

.before {
    display: flex;
    justify-content: space-between;

    // Add bottom margin to `.before`'s direct children. If elements before the
    // input are visually empty, no margin should display before the input.
    > * {
        margin-bottom: @spacing-2;
    }
}

// Add top margin to elements after the input. If no elements exist after the
// input, no margin should display after the input.
.after {
    margin-top: @spacing-2;
}

.secondary {
    color: var(--text-secondary-color);

    &.nonText {
        line-height: 0px;
    }
}

.error .inputWrapper {
    border-color: var(--border-error-color);

    &:focus-within {
        outline-color: var(--border-error-color);
    }
}

.errorMessage {
    color: var(--text-error-color);

    a {
        color: inherit;
    }
}
