@import (reference) '@mc/wink/styles/tokens.less';

.visibilityToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    color: var(--interactive-color);

    & svg {
        width: 24px;
        height: 24px;
        fill: currentColor;
        margin-right: 6px;
    }
}
