/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import (reference) '@mc/wink/styles/tokens.less';

@bottom-sheet-border-radius: @base-unit * 3;

@wide-min-width: 400;
@wide-max-width: 1440;
@wide-min-margin: 8;
@wide-max-margin: 24;
@unit: 1px;

// Styles for dialog container
.container {
    display: flex;
    position: fixed;
    bottom: 0;
    top: 5%;
    width: 100%;
    padding: 0 ~"calc(((clamp(@{wide-min-width} * @{unit}, 100%, @{wide-max-width} * @{unit}) - @{wide-min-width} * @{unit}) * (@{wide-max-margin} - @{wide-min-margin}) / (@{wide-max-width} - @{wide-min-width})) + @{wide-min-margin} * @{unit})";

    @media (prefers-reduced-motion) {
        transition: none;
    }

    &.expandable {
        &.dragging {
            transition: none;
        }

        &.closed,
        &.expanded,
        &.open {
            transition: top @animation-speed-slow @animation-curve;
        }

        &.closed {
            // overrides inline style top pixel value
            top: 100% !important;
        }

        &.open {
            // overrides inline style top pixel value
            top: 65% !important;
        }

        &.expanded {
            // overrides inline style top pixel value
            top: 5% !important;
        }

        &:not(.expanded) .body {
            overflow: hidden;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--bg-underlay-color);
    z-index: @z-modal;
}

.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--bg-page-color);
    border-top-left-radius: @bottom-sheet-border-radius;
    border-top-right-radius: @bottom-sheet-border-radius;

    // @media (prefers-reduced-motion) {
    //     transition: none;
    // }
}

.dragIndicator {
    position: absolute;
    top: @spacing-2;
    left: 50%;
    height: @base-unit;
    width: @base-unit * 8;
    margin-left: @base-unit * -4;
    border-radius: 40px;
    background: var(--border-light-color);
}

.header {
    position: sticky;
    padding: @spacing-6;
}


.body {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    font: var(--font-text-medium);
    padding: @spacing-6;
    padding-top: 0;
}

// Apply this global to the content that should recede when the modal is open
:global(.wink-bottom-sheet-receding-content) {
    @media (prefers-reduced-motion: no-preference) {
        transition: all @animation-speed-slow @animation-curve;
    }

    box-shadow: 0 0 0 60px var(--border-dark-color);
    overflow: auto;

    @media (prefers-reduced-motion) {
        transition: none;
    }
}

:global(.wink-bottom-sheet-receding-content-active) {
    transform: scale(0.95);
    border-radius: @bottom-sheet-border-radius;
    border-collapse: separate;
    box-shadow: 0 0 0 120px var(--border-dark-color);
}

// here be dragons until we can use form inside the Modal body
.root > form {
    min-height: 0;
}

// scroll lock

.lockScroll {
    height: 100vh;
    overflow: hidden;
}

