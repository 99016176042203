@import (reference) '@mc/wink/styles/tokens.less';
// TO-DO: FEP to figure out a better solution for this. If nested to a specific element, theme variables are not applied.
/* stylelint-disable css-modules/no-global-scoped-selector */
:root {
    --button-border-width: 1px;
    --button-secondary-border-width: 1px;
    --button-secondary-background: transparent;
    --button-secondary-text-color: var(--interactive-color);
    --button-secondary-text-color-hover: var(--interactive-hover-color);
}

:global(.dark-mode) {
    // Semantic tokens used for component level tokens need to be redefined in order to apply to dark mode
    --button-secondary-text-color: var(--interactive-color);
    --button-secondary-text-color-hover: var(--interactive-hover-color);
}

:global(.project-runway) {
    // Changes were not applying with multiple reassignments of the same variable
    // Not an issue when not flagged
    --button-secondary-text-color: var(--color-ui-primary);
    --button-secondary-text-color-hover: var(--color-ui-primary-hover);
}

.container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font: var(--font-text-small);
    font-weight: var(--font-weight-bold);
    // Specific line-height and padding set for large button height
    line-height: calc(var(--base-unit) * 11);
    padding: 0 calc(var(--base-unit) * 8);

    white-space: nowrap;
    user-select: none;
    border-radius: var(--border-pill-radius);
    transition:
        box-shadow var(--duration-base) ease-in-out 0s,
        color var(--duration-base) ease-in-out 0s,
        background-color var(--duration-base) ease-in-out 0s,
        transform var(--animation-speed-fast) ease-in-out 0s;

    &:focus {
        outline: calc(var(--base-unit) * 0.5) solid var(--border-interactive-color);
        outline-offset: 1px;
    }

    &:active {
        transform: translateY(4px);

        &:focus {
            outline: 0;
            outline-offset: 0;
        }

        @media (prefers-reduced-motion) {
            transform: translateY(0);
        }
    }

    & svg {
        margin-right: var(--base-unit);
    }
}

/******************
* TYPES
******************/

.primary {
    color: var(--cta-primary-text, var(--bg-lvl-1-color));
    background-color: var(--interactive-color);
    text-decoration: none;

    &:hover,
    &:active {
        color: var(--cta-primary-text, var(--bg-lvl-1-color));
        background-color: var(--interactive-hover-color);
    }

    &[disabled],
    a&:not([href]) {
        pointer-events: none;
        opacity: var(--disabled-button-opacity);
        background-color: var(--interactive-color);
    }
}

.secondary {
    // The box-shadow/border must match the text color.
    color: var(--button-secondary-text-color);
    box-shadow:
        inset 0 0 0 var(--button-secondary-border-width)
        var(--interactive-color);
    background-color: var(--button-secondary-background);
    text-decoration: none;

    &:hover,
    &:active {
        color: var(--button-secondary-text-color-hover);
        box-shadow:
            inset 0 0 0 var(--button-border-width)
            var(--interactive-hover-color);
        background-color: var(--bg-interactive-color);
    }

    // Color 2.0 focus style
    &:focus {
        box-shadow:
            inset 0 0 0 var(--button-border-width)
            var(--interactive-hover-color);
        background-color: var(--button-secondary-background);
    }

    &[disabled],
    a&:not([href]) {
        pointer-events: none;
        color: var(--button-secondary-text-color);
        opacity: var(--disabled-button-opacity);
        box-shadow:
            inset 0 0 0 var(--button-secondary-border-width)
            var(--interactive-color);
        background-color: var(--button-secondary-background);
    }
}

// Buttons that visually look like links - the spacing and usage on these will differ since
// they should not be treated as inline styling
.tertiary {
    color: var(--interactive-color);
    background-color: transparent;
    text-decoration: none;
    padding: 0 var(--spacing-4);

    &:hover,
    &:active {
        color: var(--interactive-hover-color);
        background-color: var(--bg-interactive-color);
    }

    &[disabled],
    a&:not([href]) {
        pointer-events: none;
        color: var(--interactive-color);
        opacity: var(--disabled-button-opacity);
    }
}

// Branded button variants
.branded-primary {
    color: var(--text-branded-color);
    background-color: var(--branded-interactive-color);
    box-shadow: inset 0 0 0 var(--button-border-width) var(--border-color);
    text-decoration: none;

    &:hover,
    &:active {
        color: var(--text-branded-color);
        box-shadow: inset 0 0 0 var(--button-border-width) var(--text-branded-color);
        background-color: var(--branded-primary-hover-color);
    }

    &:focus {
        background-color: var(--branded-primary-hover-color);
        box-shadow: inset 0 0 0 var(--button-border-width) var(--text-branded-color);
        outline: 2px solid var(--text-branded-color);
    }

    &[disabled],
    a&:not([href]) {
        color: var(--bg-lvl-1-color);
        pointer-events: none;
        background-color: var(--disabled-button-color);
        box-shadow: none;
    }
}

.branded-secondary {
    color: var(--text-branded-color);
    box-shadow:
        inset 0 0 0 var(--button-secondary-border-width)
        var(--text-branded-color);
    background-color: var(--branded-secondary-color);
    text-decoration: none;

    &:hover,
    &:active {
        color: var(--text-branded-color);
        box-shadow: inset 0 0 0 var(--button-border-width) var(--text-branded-color);
    }

    &:focus {
        color: var(--text-branded-color);
        box-shadow: inset 0 0 0 var(--button-border-width) var(--text-branded-color);
        outline: 2px solid var(--text-branded-color);
    }

    &[disabled],
    a&:not([href]) {
        pointer-events: none;
        color: var(--disabled-button-color);
        box-shadow:
            inset 0 0 0 var(--button-border-width)
            var(--disabled-button-color);
        background-color: transparent;
    }
}

.branded-tertiary {
    color: var(--text-branded-color);
    background-color: transparent;
    text-decoration: none;

    &:hover,
    &:active {
        color: var(--text-branded-color);
        background-color: var(--branded-secondary-color);
    }

    &:focus {
        color: var(--text-branded-color);
        background-color: var(--branded-secondary-color);
        outline: 2px solid var(--text-branded-color);
        outline-offset: 0;
    }

    &[disabled],
    a&:not([href]) {
        pointer-events: none;
        color: var(--disabled-button-color);
        background-color: transparent;
    }
}

/******************
* SIZE
******************/

.small {
    padding: 0 calc(var(--base-unit) * 3);
    line-height: calc(var(--base-unit) * 6);
}

.medium {
    padding: 0 calc(var(--base-unit) * 4);
    line-height: calc(var(--base-unit) * 8);
}

.tertiary {
    &.small {
        padding: 0 var(--spacing-2);
    }
    &.medium {
        padding: 0 var(--spacing-2);
    }
}

/******************
* PURPOSE
******************/

.passive {
    background-color: var(--bg-lvl-3-color);
    color: var(--text-primary-color);

    &:hover {
        background-color: var(--bg-lvl-4-color);
        color: var(--text-primary-color);
    }
    :global(.project-runway) &:hover {
        background-color: var(--color-action-passive-hover);
    }

    &:disabled,
    a&:not([href]) {
        opacity: var(--disabled-button-opacity);
        color: initial;
    }

    &.secondary {
        background-color: var(--bg-lvl-1-color);
        box-shadow:
            inset 0 0 0 var(--button-secondary-border-width)
            var(--bg-lvl-4-color);

        &:hover {
            background-color: var(--bg-lvl-3-color);
            box-shadow:
                inset 0 0 0 var(--button-secondary-border-width)
                var(--brand-tuna); // no theming variable for tuna
        }
        :global(.project-runway) &:hover {
            background-color: var(--color-action-passive-subtle-hover);
            box-shadow:
                inset 0 0 0 var(--button-secondary-border-width)
                var(--color-action-passive-border);
        }
    }

    &.tertiary {
        background-color: var(--bg-lvl-1-color);

        &:hover {
            background-color: var(--bg-lvl-3-color);
        }
        :global(.project-runway) &:hover {
            background-color: var(--color-action-passive-subtle-hover);
        }
    }
}

/******************
* LOADING
******************/

.loading {
    position: relative;
    height: 2px;
    width: calc(var(--base-unit) * 5);
    margin-right: var(--spacing-2);
    overflow: hidden;
    background: var(--border-light-color);
    &::before {
        content: '';
        position: absolute;
        left: 0%;
        width: 0%;
        height: 100%;
        background: currentColor;
        animation: progress 1s var(--animation-curve) infinite;
    }
}

// Hack to deal while FP-1214 gets properly fixed
.temporarySpan {
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    white-space: inherit;
}

// Keyframes for loading indicator
@keyframes progress {
    0% {
        width: 0%;
    }
    50% {
        width: 100%;
    }
    51% {
        left: 0%;
    }
    100% {
        left: 120%;
    }
}
