@import (reference) '@mc/wink/styles/tokens.less';

.container {
    max-width: @readable-max-width;
}

.altStyleContainer {
    border: 1px solid var(--border-light-color);
    padding: @spacing-6 @spacing-6;
}

.altText {
    // height: 110px;
}

.file {
    border: 1px solid var(--border-light-color);
    background-color: var(--bg-lvl-1-color);
    padding: @spacing-3 @spacing-6;
    max-width: @readable-max-width;
}

.nameText {
    overflow-wrap: anywhere;
}

.removeButton {
    min-width: @spacing-7;
}

.thumbnail {
    max-width: 100px;
}
