@import (reference) '@mc/wink/styles/tokens.less';

.option {
    cursor: pointer;
    padding: @spacing-3 @spacing-5;
    display: flex;
    align-items: center;
    word-break: break-word;

    &[aria-selected="true"] {
        background-color: var(--bg-lvl-3-color);
        color: var(--text-primary-color);

        .selectedIcon {
            fill: var(--interactive-color);
            margin-left: auto;
            flex-shrink: 0;
        }
    }

    &:not([aria-selected="true"]) .selectedIcon {
        visibility: hidden;
    }

    &[aria-disabled="true"] {
        --text-secondary-color: var(--disabled-color);
        color: var(--disabled-color);
        cursor: not-allowed;

        &:hover {
            background-color: initial;
        }
    }
}

.isHighlighted {
    --text-secondary-color: var(--text-inverted-color);
    background-color: var(--bg-inverted-color);
    color: var(--text-inverted-color);

    &[role="option"] > :not(span) {
        color: var(--interactive-inverted-color) !important;
    }

    &[aria-selected="true"] {
        background-color: var(--bg-inverted-color);
        color: var(--text-inverted-color);

        .selectedIcon {
            fill: var(--interactive-inverted-color);
        }
    }
}
