
.option {
    cursor: pointer;
    padding: var(--spacing-3) var(--spacing-5);
    display: flex;
    align-items: center;
    word-break: break-word;

    .selectedIcon {
        /*  Push "Check/Selected" icon to the right */
        margin-left: auto;
    }

    /*  Selected items */
    &[aria-selected="true"] {
        background-color: var(--bg-lvl-3-color);
        color: var(--text-primary-color);

        .selectedIcon {
            fill: var(--interactive-color);
        }
    }

    /*  Highlighted items */
    &.isHighlighted {
        background-color: var(--bg-inverted-color);
        color: var(--text-inverted-color);

        .selectedIcon {
            fill: var(--text-inverted-color);
        }
    }
}
