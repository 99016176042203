@import (reference) '@mc/wink/styles/tokens.less';

@standard-min-width: 400;
@standard-max-width: 1192;
@standard-min-margin: 16;
@standard-max-margin: 80;

@wide-min-width: 400;
@wide-max-width: 1440;
@wide-min-margin: 16;
@wide-max-margin: 24;

@unit: 1px;

// This is roughly equal to 70ch (@readable-max-width)
@narrow-max-width: 762;

.root {
    margin-left: auto;
    margin-right: auto;
}

.standard {
    // Fallback for browsers that don't support clamp()
    padding: 0 @standard-min-margin * @unit;
    padding: 0 ~"calc(((clamp(@{standard-min-width} * @{unit}, 100%, @{standard-max-width} * @{unit}) - @{standard-min-width} * @{unit}) * (@{standard-max-margin} - @{standard-min-margin}) / (@{standard-max-width} - @{standard-min-width})) + @{standard-min-margin} * @{unit})";
    max-width: @standard-max-width * @unit;
}

.narrow {
    padding: 0 @standard-min-margin * @unit;
    max-width: @narrow-max-width * @unit;
}

.wide {
    // Fallback for browsers that don't support clamp()
    padding: 0 @wide-min-margin * @unit;
    padding: 0 ~"calc(((clamp(@{standard-min-width} * @{unit}, 100%, @{standard-max-width} * @{unit}) - @{standard-min-width} * @{unit}) * (@{standard-max-margin} - @{standard-min-margin}) / (@{standard-max-width} - @{standard-min-width})) + @{standard-min-margin} * @{unit})";
}
