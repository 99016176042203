@import (reference) '@mc/wink/styles/tokens.less';

.listbox {
    background: var(--bg-lvl-1-color);
    box-shadow: var(--elevation-lvl-1);
}

.options {
    max-width: @readable-max-width;
    max-height: 400px;
    overflow: auto;
}

:not(.optgroupWrapper) + .optgroupWrapper {
    border-top: 1px solid var(--border-light-color);
}

.optgroupWrapper:not(:last-child) {
    border-bottom: 1px solid var(--border-light-color);
    padding-bottom: @spacing-2;
}

.optgroup {
    color: var(--text-secondary-color);
    font: @font-weight-bold var(--font-text-small);
    text-transform: uppercase;
    padding: @spacing-4 @spacing-5 @spacing-3;
    display: flex;
    word-break: break-word;
    position: sticky;
    top: 0;
    background-color: var(--bg-lvl-1-color);
}

.callToAction {
    border-top: 1px solid var(--brand-peppercorn-15);
    padding: calc(var(--base-unit) * 2.5) calc(var(--base-unit) * 4);

    a {
        font-weight: var(--font-weight-bold);
        color: var(--interactive-color);
        &:hover,
        &:focus {
            color: var(--interactive-hover-color);
            text-decoration: underline;
        }
    }
}
