@import (reference) '@mc/wink/styles/tokens.less';

.root {
    display: flex;
    justify-content: flex-end;
    // gap and align-items are not needed if announcements are used as laid out in the guidelines,
    // which specify only one announcement at a time should be shown.
    // However, as a precaution in case more than one are shown,
    // we should at least make them look nice next to each other.
    align-items: flex-end;
    gap: var(--spacing-3);
    position: fixed;
    width: auto;
    bottom: calc(var(--spacing-8) + 12px); // bump an extra 12px to avoid "?" icon popup
    right: var(--spacing-8);
    z-index: var(--z-menus);

    @media screen and (max-width: @phone) {
        bottom: var(--spacing-9);
        left: 50%;
        transform: translateX(-50%);
        right: auto;
    }

}
