@import (reference) '@mc/wink/styles/tokens.less';

.root {
    max-width: var(--readable-max-width);
}

/*  HEADING SETS */
.heading-2 {
    font: var(--font-heading-2);
    font-weight: var(--font-heading-weight);
    letter-spacing: var(--letter-spacing-heading);
}

.heading-3 {
    font: var(--font-heading-3);
    font-weight: var(--font-heading-weight);
    letter-spacing: var(--letter-spacing-heading);
}

:global(.project-runway) {
    .heading-3 {
        font: var(--font-heading-5);
        font-weight: var(--font-heading-weight);
    }
}

.heading-4 {
    font: var(--font-heading-4);
    font-weight: var(--font-heading-weight);
    letter-spacing: var(--letter-spacing-heading);
}

:global(.project-runway) {
    .heading-5 {
        font: var(--font-heading-5);
        font-weight: var(--font-heading-weight);
    }

    .heading-6 {
        font: var(--font-heading-6);
        font-weight: var(--font-heading-weight);
    }
}

/*  LARGE */
.large {
    font: var(--font-text-large);
    letter-spacing: var(--letter-spacing-body);
}

.large-secondary {
    font: var(--font-text-large);
    color: var(--text-secondary-color);
    letter-spacing: var(--letter-spacing-body);
}

.large-bold {
    font: var(--font-text-large);
    font-weight: var(--font-weight-bold);
    letter-spacing: var(--letter-spacing-body-bold);
}

/*  MEDIUM */
.medium {
    font: var(--font-text-medium);
    letter-spacing: var(--letter-spacing-body);
}

.medium-secondary {
    font: var(--font-text-medium);
    color: var(--text-secondary-color);
    letter-spacing: var(--letter-spacing-body);
}

.medium-bold {
    font: var(--font-text-medium);
    font-weight: var(--font-weight-bold);
    letter-spacing: var(--letter-spacing-body-bold);
}

/*  SMALL */
.small {
    font: var(--font-text-small);
    letter-spacing: var(--letter-spacing-body);
}

.small-secondary {
    font: var(--font-text-small);
    color: var(--text-secondary-color);
    letter-spacing: var(--letter-spacing-body);
}

.small-bold {
    font: var(--font-text-small);
    font-weight: var(--font-weight-bold);
    letter-spacing: var(--letter-spacing-body-bold);
}

/*  SMALL */
.tiny {
    font: var(--font-text-tiny);
    letter-spacing: var(--letter-spacing-body);
}

.tiny-secondary {
    font: var(--font-text-tiny);
    color: var(--text-secondary-color);
    letter-spacing: var(--letter-spacing-body);
}

.tiny-bold {
    font: var(--font-text-tiny);
    font-weight: var(--font-weight-bold);
    letter-spacing: var(--letter-spacing-body-bold);
}

/*  TEXT WRAP */
.text-wrap {
    text-wrap: wrap;
}
.text-nowrap {
    text-wrap: nowrap;
}
.text-balance {
    text-wrap: balance;
}
.text-pretty {
    text-wrap: pretty;
}
