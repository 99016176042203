@import (reference) '@mc/wink/styles/tokens.less';

// List margin is reduced for FormFeedback only
.list {
    margin: 0;

    li {
        margin: 0;
    }
}
