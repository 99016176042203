@import (reference) '@mc/wink/styles/tokens.less';

@popover-background: var(--bg-lvl-1-color);

.popover {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: @z-modal;
}

.shadow {
    background: @popover-background;
    box-shadow: @elevation-lvl-1;
}
