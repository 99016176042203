@import (reference) '@mc/wink/styles/tokens.less';

/*------------------------------------*\
    #Pull Quote Root
\*------------------------------------*/

.root {
    padding-top: var(--spacing-11);
    padding-bottom: var(--spacing-11);
}

/*------------------------------------*\
    #Eyebrow
\*------------------------------------*/

.eyebrow {
    text-transform: uppercase;
}


/*------------------------------------*\
    #Quote
\*------------------------------------*/

.quoteContainer {
    margin-left: 0;
    margin-top: var(--spacing-2);
}

/*------------------------------------*\
    #Secondary
\*------------------------------------*/

.secondaryContainer {
    padding-top: var(--spacing-4);
}

/*------------------------------------*\
    #CallToAction
\*------------------------------------*/

.callToAction {
    padding-top: var(--spacing-4);
}

/*------------------------------------*\
    #Media
\*------------------------------------*/

.mediaContainer {
    flex-grow: 1;
    justify-content: flex-end;
    display: flex;
}
