/* File allow-listed for globally scoped CSS. Reach out to #fep-team with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

/*********************
    IDS Overrides

    Due to our current configuration with wink prefixes on our global elements and classes,
    WinkStyles is overriding some IDS components styles due to CSS specificity.
    We are implementing this global override file so teams do not have to implement them on an individual basis.

    This file is to quickly unblock product teams using AppFabric. We will be working on a long term solution.
********************/

:global {
    .idsTSButton {
        border-radius: var(--border-radius-lv6) !important;
        font-size: var(--font-size-small) !important;
        font-weight: var(--font-weight-bold) !important;
    }

    .idx-widget-title,
    .integration-settings-title {
        // Temporary fix to match IDS Headline 3 specs. Larger effort to map Typography specs between IDS/MCDS needs to be done.
        // https://uxfabric.intuitcdn.net/internal/design-systems/ids-web/main/latest/index.html?path=/story/foundations-typography--playground&args=variant:headline-3&platform=web&theme=mailchimp&colorScheme=light
        font-size: 34px !important;
        span {
            font-weight: var(--font-weight-normal) !important;
        }
    }

    .idx-provider-details-banner .idx-provider-details-banner-meta {
        gap: var(--spacing-4) !important;
    }

    .idx-instruction-action-steps {
        gap: var(--spacing-4) !important;
    }
}
