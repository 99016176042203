@import (reference) '@mc/wink/styles/tokens.less';

.root {
    max-width: var(--readable-max-width);
    vertical-align: middle;
}

.before {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-2);
}

// Add top margin to elements after the input. If no elements exist after the
// input, no margin should display after the input.
.after {
    margin-top: var(--spacing-2);
}

.action {
    align-self: flex-end;
}

.label {
    line-height: @line-height-medium;
}

.meterTrack {
    --meter-track-color: var(--border-light-color);
    --meter-fill-color: var(--text-primary-color);
    display: flex;
    width: 100%;
    background-color: var(--meter-track-color);
    border-radius: var(--border-pill-radius);

    .meterFill {
        background-color: var(--meter-fill-color);
        display: flex;
        height: @spacing-2;
        border-radius: var(--border-pill-radius) 0 0 var(--border-pill-radius);
    }
}

.positive {
    --meter-fill-color: var(--text-success-color);
}

.warning {
    --meter-fill-color: var(--border-warning-color);
}


.negative {
    --meter-fill-color: var(--text-error-color);
}


.large {
    .meterFill {
        height: var(--spacing-6);
    }
}


.small {
    .meterFill {
        height: var(--spacing-1);
    }
}

.tiny {
    .meterFill {
        height: calc(var(--spacing-1) / 2);
    }
}
