@import (reference) '@mc/wink/styles/tokens.less';

.root {
    background-color: var(--bg-lvl-1-color);
    transition: background-color var(--duration-base) ease;

    &:first-of-type .wrapper {
        border-top: none;
    }
}

.wrapper {
    position: relative;
    padding: var(--spacing-5) 0;
    border-top: 1px solid var(--border-light-color);

    display: grid;
    grid-column-gap: var(--spacing-4);
    grid-row-gap: var(--spacing-4);
    grid-template-columns: 50% auto 15%;
    grid-template-areas: "content stats action";

    @media screen and (max-width: @phone) {
        grid-template-columns: auto auto;
        grid-template-areas:
            "selection selection"
            "content content"
            "stats iconaction"
            "action action";
    }
}


/* Selectable Slats */
.interactive {
    padding: 0 var(--spacing-9);
    border-radius: var(--border-card-radius);

    &:hover,
    &:focus-within {
        background-color: var(--bg-lvl-2-color);
    }
}

.selection {
    position: absolute;
    left: calc(var(--spacing-8) * -1);
    top: 50%; // To get absolute center positioning at all times depending on content
    margin-top: calc(var(--spacing-3) * -1); // Half of checkbox height
}

/** Content for Slat */
.thumbnail {
    max-width: 60px;
}

.date {
    font: var(--font-text-small);
    color: var(--text-secondary-color);
    margin-left: @spacing-3;
}

.content {
    grid-area: content;
    display: flex;
    > img { margin-right: var(--spacing-4); }

}

.toggle {
    margin-left: var(--spacing-2);
    align-self: flex-end;
}

.stats {
    grid-area: stats;
    display: flex;
    dl {
        margin-right: var(--spacing-4);
    }
    dt {
        font: var(--font-text-small);
        color: var(--text-secondary-color);
    }
}

/** Position of Slat actions
* Must behave differently in mobile for different types of actions */
.action,
.iconAction {
    grid-area: action;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: @phone) {
        margin-left: 0;
    }
}

.iconAction {
    @media screen and (max-width: @phone) {
        grid-area: iconaction;
    }
}


/** Nested Slat styles*/
.child {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition:
        visibility 0s linear var(--duration-base),
        opacity var(--duration-base) linear,
        max-height var(--duration-base) var(--animation-curve);

    .root {
        background-color: transparent;
        padding: 0;
    }

    .wrapper {
        border-top: none;
    }
    .selection {
        left: calc(var(--spacing-6) * -1);

        @media screen and (max-width: @phone) {
            left: inherit;
        }
    }
    .content {
        padding-left: var(--spacing-6);

        // Remove indentation of child slats in mobile
        @media screen and (max-width: @phone) {
            padding-left: 0;
        }
    }
}
.expanded {
    visibility: visible;
    opacity: 1;
    max-height: 2000px;
    transition:
        visibility 0s linear,
        opacity var(--duration-base) linear,
        max-height var(--duration-base) var(--animation-curve);
}
