
/*  Allows clicks to pass-through the chevron to the underlying input. */
.root {
    [data-input-suffix] {
        position: absolute;
        right: 0;
        pointer-events: none;
    }

    input {
        padding-right: 44px;
    }
}

.chevron {
    display: block;
}

.popup {
    background: var(--bg-lvl-1-color);
    box-shadow: var(--elevation-lvl-1);
    border-radius: var(--border-overlay-radius);
    max-width: var(--readable-max-width);
    min-width: 400px;
    max-height: 400px;
    overflow: auto;
}
